import React from 'react';
import MoneyJar from '../../libs/assets/money-jar.svg';
import PlantBag from '../../libs/assets/plant-bag.svg';

const Contributions = () => {
  return (
    <div className="contribution">
      <div>
        <h6>Awabah Contributions</h6>
        <p>This plan allows parrticipants save towards short and medium term goals. Plan features include:</p>
        <div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">
              Competitive interest rates that are benchmarked against the average Nigerian Bank Savings Rate (BSR)
            </p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">Flexible plan and the payment frequency. Pay whatever you can whenever you can</p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">Minimum monthly contribution of N5,000.</p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">6 month tenure</p>
          </div>
        </div>
        <button className="btn btn-outline-dark btn-default">Get Started</button>
      </div>
      <div className="moneyJar">
        <img src={MoneyJar} alt=" " />
      </div>
      <div className="moneyJar">
        <img src={PlantBag} alt=" " />
      </div>
      <div>
        <h6>Awabah Contributions Plus</h6>
        <p>
          Similar to the Contributions plan this plan helps iparticipants meet more long term financial targets.
          Features include:
        </p>
        <div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">
              Competitive interest rates that are benchmarked against the average Nigerian Bank Savings Rate (BSR)
            </p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">Flexible plan and the payment frequency. Pay whatever you can whenever you can</p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">Minimum monthly contribution of N10,000.</p>
          </div>
          <div className="d-flex">
            <i className="fa fa-check-circle"></i>
            <p className="mt-1">3 year minimum tenure</p>
          </div>
        </div>
        <button className="btn btn-outline-dark btn-default">Get Started</button>
      </div>
    </div>
  );
};

export default Contributions;
