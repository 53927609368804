import React from 'react';
import { QuestionsStyles } from '../../styled/StyledQuestions';
import CustomLink from '../shared/core/CustomLink';

const Questions = () => {
  return (
    <div className="got-questions">
      <div style={{ paddingLeft: '40px' }}>
        <p className="big-text">Got Questions?</p>
        <button className="btn btn-outline-dark btn-default">See our FAQs</button>
      </div>

      <div style={{ width: '91%' }}>
        <div>
          <QuestionsStyles>What is a Pension?</QuestionsStyles>
          <p className="answers">
            A Pension is a regular income received by a person after retirement to cater for their needs.
          </p>
        </div>
        <div>
          <QuestionsStyles>What is a Micro Pension Plan?</QuestionsStyles>
          <p className="answers">
            Micro Pension Plan refers to an arrangement under the Contributory Pension Scheme (CPS) that allows the
            self-employed and persons working in organisations with less than three (3) employees to make financial
            contributions towards the provision of pension at their retirement or incapacitation.{' '}
          </p>
        </div>
        <div>
          <QuestionsStyles>Why Micro Pension?</QuestionsStyles>
          <p className="answers">
            Micro Pension guarantees secured future through steady income at retirement. It reduces old age poverty and
            the process is easy, simple and flexible.{' '}
          </p>
        </div>
        <CustomLink name="See All FAQS" to="" />
      </div>
    </div>
  );
};

export default Questions;
