import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../libs/assets/logo2_3.png';

const Footer = () => {
  return (
    <footer className="col-lg-12 d-flex py-2">
      <div className="col footer-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="col mt-2 footer-privacy text-right p-0">
        <Link to="#">Privacy Policy</Link>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <Link to="#">Terms</Link>
      </div>
    </footer>
  );
};

export default Footer;
