import React from 'react';
import Saving from '../../libs/assets/saving.png';
import Pencom from '../../libs/assets/number.png';
import Time from '../../libs/assets/time.png';
import Wallet from '../../libs/assets/wallet.png';
import CustomImage from '../shared/core/CustomImage';
import { SIGN_UP_URL } from '../../libs/api/base/BaseUrl';

const Benefits = () => {
  return (
    <div className="row mt-5 benefit-benefit">
      <div className="col-lg-10 m-auto text-center">
        <h5 className="my-4">
          <strong>
            Benefits of the Micro Pension Scheme
            <span className="text-success">.</span>
          </strong>
        </h5>
        <div className="benefit-box d-flex flex-wrap">
          <div className="box">
            <img src={Saving} alt="savings" />
            <h6>Tax Deductible</h6>
            <small>Contributions to the micro pension scheme are tax free</small>
          </div>

          <div className="box">
            <CustomImage src={Pencom} alt="pencom" classname="pencom-benefit-image" width="" />
            <h6>PENCOM Number</h6>
            <small>Every contributor gets a Personal Identification Number upon opening a micro pension account</small>
          </div>

          <div className="box">
            <img src={Time} alt="time" />
            <h6>Flexible Contribution Frequency & Amount</h6>
            <small>Contribute any amount and save at any time. Your convenience is key.</small>
          </div>

          <div className="box">
            <img src={Wallet} alt="wallet" />
            <h6>Withdrawals</h6>
            <small>You can draw on contingent funds after the first 3 months of contribution</small>
          </div>
        </div>
        <div className="acct-banner my-5">
          <p className="text-white">
            Start working towards a secure financial future.
            <br />
            Create an Awabah account.
          </p>

          <a href={`${SIGN_UP_URL}`} className="mr-3" target="_blank" rel="noopener noreferrer">
            <button className="btn take-assess px-5">Create an account</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
