import React from 'react';
type CustomButtonProps = {
  classname: string;
  name: string;
};
function CustomButton({ classname, name }: CustomButtonProps) {
  return <button className={classname}>{name}</button>;
}

export default CustomButton;
