import styled from "styled-components";

export const PrivacyStyle = styled.div`
    .sub_mission{
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #333333;
    }
    .privacy_header{
        text-align: center;
        h4{
            font-style: normal;
            font-weight: 600;
            font-size: 55px;
            line-height: 80px;
            color: #30332F;
        }
        small{
            font-size: 16px;
            line-height: 30px;
            color: #888888;
        }  
    }

    .in_the_news{
        font-weight: bold;
        font-size: 41px;
        line-height: 78px;
        color: #262626;
    }

    .news{
        display: grid;
        padding-left: 40px !important;
        padding-right: 40px !important;
        grid-template-columns: 1fr repeat(2, 1fr);
        grid-gap: 32px;
        .news_image img{
            width: 100%;
            border-radius:6px;
        }
        .news_details p{
            font-size: 14px;
            line-height: 24px;
        }
        .green_text{
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            color: #3D663D;
            margin: 8px 0px;
        }
        .sub_text{
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #262626;
            margin: 8px 0px;
        }
    }
    .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
      /* Backend Panel Start */
      .clear-backend {
        background: #fff;
        width: 100%;
        height: 800px;
        position: relative;
      }
      .clear-backend > input {
        position: absolute;
        filter: alpha(opacity=0);
        opacity: 0;
      }
      .clear-backend > input:hover {
        cursor: pointer;
      }
      .clear-backend > input:hover + span,
      .clear-backend > input:checked + span {
        background: #fff;
        color: #3D663D;
        border-left: 4px solid #3D663D;
      }
      .clear-backend > span,
      .clear-backend > i {
        -webkit-transition: all .5s;
           -moz-transition: all .5s;
           -o-transition: all .5s;
            transition: all .5s;
      }
      .clear-backend > input,
      .clear-backend > span {
        // background: #f0f0f0;
        display: block;
        width: 300px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        z-index: 9;
        padding-left: 14px;
        margin-bottom: 15px;
      }
      .tab-content {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% - 300px);
        height: 100%;
        overflow: hidden;
        background-color: #e5e5e5;
      }
      .tab-content section {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 20px;
        display: none;
      }
      .clear-backend > input.tab-1:checked ~ .tab-content .tab-item-1 {
        display: block;
      }
      .clear-backend > input.tab-2:checked ~ .tab-content .tab-item-2 {
        display: block;
      }
      .clear-backend > input.tab-3:checked ~ .tab-content .tab-item-3 {
        display: block;
      }
      .clear-backend > input.tab-4:checked ~ .tab-content .tab-item-4 {
        display: block;
      }
      .clear-backend > input.tab-5:checked ~ .tab-content .tab-item-5 {
        display: block;
      }
      .clear-backend > input.tab-6:checked ~ .tab-content .tab-item-6 {
        display: block;
      }
      .clear-backend > input.tab-7:checked ~ .tab-content .tab-item-7 {
        display: block;
      }
      .clear-backend > input.tab-8:checked ~ .tab-content .tab-item-8 {
        display: block;
      }
      .clear-backend > input.tab-9:checked ~ .tab-content .tab-item-9 {
        display: block;
      }
      .clear-backend > input.tab-10:checked ~ .tab-content .tab-item-10 {
        display: block;
      }
      /* Responsive */
      @media screen and (max-width: 1367px) {
      }
      @media screen and (max-width: 1281px) {
      }
      @media screen and (max-width: 1025px) {
      }
      @media only screen and (max-width: 961px) {
      }
      @media only screen and (max-width: 641px) {
        .clear-backend > input,
        .clear-backend > span {
          width: 60px;
          height: 60px;
        }
        .clear-backend > span {
          filter: alpha(opacity=0);
          opacity: 0;
        }
        .tab-content {
        width: calc(100% - 60px);
        }
      }
      @media only screen and (max-width: 481px) {
      }
      
      
      
`