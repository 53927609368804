import React from 'react';
import { Link } from 'react-router-dom';
interface CustomLinkProps {
  name: string;
  to: string;
  classname?: string;
}
const CustomLink = ({ name, to, classname }: CustomLinkProps) => {
  return (
    <Link className={classname} to={to}>
      {name}
    </Link>
  );
};

export default CustomLink;
