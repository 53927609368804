import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../libs/assets/logo2_3.png';
import UserIcon from '../libs/assets/user.png';
import SmNavIcon from '../libs/assets/navbar.png';
import CustomImage from '../components/shared/core/CustomImage';

const DashboardNav = () => {
  return (
    <div className="d-flex col-lg-12 mt-3 p-0">
      <div className="col-lg-9 logo-container">
        <CustomImage width="" src={Logo} alt="awabah-log" classname="logo wm" />
      </div>
      <div className="col-lg-3 text-right nav-item dropdown p-0">
        <Link
          className="nav-link dropdown-toggle pr-0"
          to="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <CustomImage width="" classname="d-none d-md-block d-lg-block" src={UserIcon} alt="icon" />
          <CustomImage width="" classname="d-md-none d-lg-none d-sm-block navv-img" src={SmNavIcon} alt="icon" />
        </Link>
      </div>
    </div>
  );
};
export default DashboardNav;
