import styled from 'styled-components';

export const OurProducts = styled.div`
  text-align: center;
  h4 {
    font-weight: bold;
    font-size: 41px;
    line-height: 78px;
    color: #262626;
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #262626;
  }
  .carousel-inner {
    position: relative;
    width: 95%;
    overflow: hidden;
  }
  .carousel-indicators {
    position: absolute;
    transform: rotate(90deg);
    justify-content: right;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 40px;
    right: 0px;
    left: auto;
    width: auto;
  }
  .carousel-indicators li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  .carousel-caption p {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
  }
  .carousel {
    margin-top: 2%;
  }
`;
