import styled from 'styled-components';
import FAQ from '../libs/assets/FAQs.svg';

export const ProductsContainer = styled.div`
  h4 {
    font-weight: 600;
    font-size: 45px;
    line-height: 65px;
  }

  .more-products {
    background-color: #3d663d;
    position: absolute;
    width: 100%;
    left: 0;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 45px;
      color: #ffffff;
    }
    small {
      font-weight: normal;
      font-size: 17px;
      line-height: 40px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .contribution {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 155px;
    grid-row-gap: 60px;
    position: relative;
    margin-top: 18%;
    padding-left: 40px;
    padding-right: 40px;
    p {
      margin-top: 25px;
      margin-bottom: 15px;
    }
    h6 {
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      color: #262626;
      margin: 5px 0px;
    }
    i {
      margin-top: 7px;
      margin-right: 25px;
    }
    .moneyJar img {
      width: 100%;
      max-width: 95%;
    }
  }

  .got-questions {
    display: grid;
    grid-column-gap: 17%;
    grid-template-columns: 20% auto;
    background-image: url(${FAQ});
    max-width: 100%;
    margin-top: 5%;
    background-repeat: no-repeat;
    background-position: left bottom;
    .big-text {
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 45px;
      color: #262626;
    }
    a {
      color: #3d663d;
    }
  }
`;
