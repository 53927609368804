import React from 'react';
type CustomImageProps = {
  src: string;
  alt: string;
  classname?: string;
  width: string;
};
const CustomImage = ({ src, alt, classname, width }: CustomImageProps) => {
  return <img src={src} alt={alt} className={classname} width={width} />;
};

export default CustomImage;
