import React from 'react';
import { CalculatorStyles } from '../../styled/StyledLanding';
import DOT1 from '../../libs/assets/dots.svg';

const RetirementCalculator = () => {
  return (
    <CalculatorStyles>
      <div className="dot1">
        <img src={DOT1} alt="" />
      </div>
      <div className="retirement-calculator">
        <div>
          <p className="text-white mb-0">How much will you have at retirement?</p>
        </div>
        <div className="px-4">
          <div className="calculate-saving py-2">
            <small className="text-white">If you have a monthly savings of</small>
            <p className="text-white">₦ 50,000</p>
          </div>

          <div className="calculate-saving py-2">
            <small className="text-white">And you save for </small>
            <select>
              <option>30 months</option>
            </select>
          </div>

          <div className="calculate-saving py-2 border-0">
            <small className="text-white">With an interest rate of </small>
            <p className="text-white"> 8.5%</p>
          </div>

          <div className="total-saving">
            <small>at retirement you will have</small>
            <p className="text-white">₦ 1,738,337.21</p>
          </div>
        </div>
        <div>
          <p className="text-white mb-0">See our retirement calculator</p>
        </div>
      </div>
    </CalculatorStyles>
  );
};

export default RetirementCalculator;
