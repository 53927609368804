import React from 'react';
import { Link } from 'react-router-dom';
import AwabahLogo from '../libs/assets/awabah_logo.svg';
import { ButtonStyles, NavStyles } from '../styled/StyledGeneralNav';
import ROUTES from '../routes/url';
import CustomLink from '../components/shared/core/CustomLink';

const GeneralNav = () => {
  return (
    <NavStyles className="navbar navbar-expand-lg navbar-light">
      <div style={{ marginRight: '5%' }}>
        <Link to="/">
          <img src={AwabahLogo} alt="" />
        </Link>
      </div>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto mt-2">
          <li className="nav-item mr-3">
            <CustomLink classname="nav-link" name="Our Products" to={ROUTES.PRODUCTS} />
          </li>
          <li className="nav-item mr-3">
            <a className="nav-link" href="#">
              Retirement Calculator
            </a>
          </li>
          <li className="nav-item">
            <CustomLink classname="nav-link" name="FAQs" to={ROUTES.FAQ} />
          </li>
        </ul>

        <ul className="navbar-nav ml-auto mt-2">
          <a href={ROUTES.EXTERNAL_LOGIN} className="mr-3">
            <ButtonStyles className="btn">Sign in</ButtonStyles>
          </a>
          <a className="nav-item take-assess" href={ROUTES.EXTERNAL_REGISTER}>
            <ButtonStyles className="btn btn-success">Sign up</ButtonStyles>
          </a>
        </ul>
      </div>
    </NavStyles>
  );
};

export default GeneralNav;
