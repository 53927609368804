/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import GeneralNav from '../../layouts/GeneralNav';
import { PrivacyStyle } from '../../styled/StyledPrivacy';
import { ProductsContainer } from '../../styled/StyledProducts';
import NewFooter from '../../layouts/NewFooter';
import Questions from '../product/Questions';
import Carousel from '../carousel/Carousel';
import Green from '../../libs/assets/Green-Shield.png';
import { VerticalTab } from './StyledPrivacy';

const Privacy = () => {
  return (
    <PrivacyStyle className="container-fluid">
      <GeneralNav />
      <div className="mt-4 privacy_header">
        <h4>Awabah Privacy Policy</h4>
        <small>Last updated 24th March, 2021</small>
      </div>
      <Carousel />

      <VerticalTab>
        <div className="group_contain">
          <div className="containing_menu">
            <div style={{ borderRight: '1px solid  #E1E1E1' }}>
              <div className="sub_menu">
                <a href="#intro">Introduction</a>
              </div>
              <div className="sub_menu">
                <a href="#who">Who we are</a>
              </div>
              <div className="sub_menu">
                <a href="#data">When we collect data about you</a>
              </div>
              <div className="sub_menu">
                <a href="#type">Types of data we collect</a>
              </div>
              <div className="sub_menu">
                <a href="#personal">How we use your personal data</a>
              </div>
              <div className="sub_menu">
                <a href="#legal">Legal reasons we collect data</a>
              </div>
              <div className="sub_menu">
                <a href="#rights">Your rights as a data subject</a>
              </div>
              <div className="sub_menu">
                <a href="#user">User profile</a>
              </div>
              <div className="sub_menu">
                <a href="#third">Sharing information with third parties</a>
              </div>
              <div className="sub_menu">
                <a href="#security">Security is taken seriously</a>
              </div>
              <div className="sub_menu">
                <a href="#retention">Retention of personal information</a>
              </div>
              <div className="sub_menu">
                <a href="#international">Internation transfer</a>
              </div>
              <div className="sub_menu">
                <a href="#links">Links to other websites</a>
              </div>
              <div className="sub_menu">
                <a href="#changes">Changes to our privacy policy</a>
              </div>
            </div>
          </div>
          <div className="detailed_part mt-5">
            <section id="intro" className="section_thing">
              <div className="text-center">
                <img src={Green} alt="" />
                <h5>AWABAH PRIVACY POLICY</h5>
              </div>
              <div>
                <h5>INTRODUCTION</h5>
                <p>
                  AWABAH NIGERIA (“We”, “Our”, or “Us”) operate https://www.awabahng.com/ (hereinafter referred to as
                  “the Platform”). The platform is built to make micro-pensions savings delightful and accessible.{' '}
                  <br />
                  <br />
                  This Privacy policy sets out in clear and concise form our practices and procedures regarding the
                  collection, use and disclosure of your personal data and sensitive personal data when you visit,
                  access, browse through and/or use our platform or services. We may change this privacy policy at any
                  time and for any reason. We encourage you to review this privacy statement each time you visit the
                  website.
                  <br />
                  <br />
                  If we decide to make a significant change to our privacy policy, we will post a notice on our platform
                  for a period of time after the change is made.
                </p>
              </div>
            </section>

            <section id="who" className="section_thing">
              <div>
                <h5>who we are</h5>
                <p>
                  Awabah Nigeria provides personalized micro-schemes which allows everyone reach their financial goals
                  through our practical investment products. <br />
                  Our goal is to be a tech pension fund which through our services will help you escape living from
                  paycheck to paycheck.
                </p>
              </div>
            </section>

            <section id="data" className="section_thing">
              <div>
                <h5>WHEN WE COLLECT DATA ABOUT YOU</h5>
                <p>
                  When you visit our Platform
                  <br />
                  When you use our Services
                  <br />
                  When you sign up to receive marketing communications from Us
                  <br />
                  When you contact us by telephone, fax, email, website, our social media pages or post to make a
                  general enquiry or discuss your prospective pensions Plan with us
                  <br />
                  When you voluntarily complete a customer survey or provide feedback on any of our message boards or
                  via email <br />
                  When you make a claim;
                  <br />
                  When you apply for a job at Awabah Nigeria
                </p>
              </div>
            </section>

            <section id="type" className="section_thing">
              <div>
                <h5>TYPES OF DATA WE COLLECT</h5>
                <p>
                  We may collect information captured in our web logs, including the website you came from (known as the
                  referring URL), any Awabah online advertisements located on third party websites that you may have
                  clicked on to access our platform(s), your device information, unique identification numbers (e.g. IP
                  address and device ID), browser information, website traffic and pages viewed, and other information
                  about how you interacted with Our Website.
                  <br />
                  We also collect information from you that includes personal or sensitive personal data — in the
                  context of our operation, personal data entails any information relating to you, through which We
                  identify or can identify you as a living individual (such as your name, gender, email address, and
                  telephone number); and sensitive personal data means any information relating to your next of kin,
                  employer details, job role occupied (where employed and also self-employed), financial service
                  providers which includes and are not restricted to the traditional banks, open banks, cryptocurrency
                  financial institutions).
                </p>
              </div>
            </section>

            <section id="personal" className="section_thing">
              <div>
                <h5>how we use your personal data</h5>
                <p>
                  We will only use the information collected from you for the purpose for which we collected it, which
                  may include the following:
                  <br />
                  ● to ensure the seamless efficiency of our Platforms for your use;
                  <br />
                  ● to register you as a new user and manage our relationship with you; <br />
                  ● to ensure accuracy of data;
                  <br />
                  ● to send you informational and promotional content that you may choose to receive from us;
                  <br />
                  ● to communicate board policies which will not govern the company and our pension scheme, <br />
                  ● to communicate total sums standing to your name in your selected pension plan, to communicate when
                  your pension is due to be received on a monthly basis as the company deems fit; <br />
                  ● to meet legal requirements, including complying with court orders, valid discovery requests, valid
                  subpoenas, and other appropriate legal mechanisms;
                  <br />
                  ● to send you important messages relating to the use and maintenance of our Platform(s). we may inform
                  you of temporary or permanent changes to our Services, such as planned outages, new features, version
                  updates, abuse warnings, and changes to our Privacy Policy, and other internal policies;
                  <br />
                  Where we need to process your information for additional purposes that we have not identified at the
                  time of collection, We will make sure to obtain your consent or the appropriate legal basis for these
                  additional uses to the extent required by applicable law.
                </p>
              </div>
            </section>

            <section id="legal" className="section_thing">
              <div>
                <h5>LEGAL REASONS WE COLLECT DATA</h5>
                <p>
                  If we have to use consent as a legal basis, we will provide you with a consent form. You have the
                  right to refuse to consent or withdraw your consent at any time by contacting us at:
                  hello@awabahng.com . However, we should mention that withdrawal of consent would not affect the
                  lawfulness of any processing carried out before you withdraw your consent.
                  <br />
                  <br />
                  <b>ii. Performance of a Contract</b>
                  <br />
                  We may also process your information on the basis that we need to perform and fulfill a contract with
                  you for the provision of pension services or to take steps at your request prior to entering a
                  contract. <br />
                  <br />
                  <b>iii. Legal Obligation</b>
                  <br />
                  We may process your information where there is a statutory or regulatory obligation to do so and it is
                  necessary to respond to a lawful request from a law enforcement or regulatory authority, body or
                  agency; in the defense of legal claims or in order to investigate, prevent or take action regarding
                  illegal activities, suspected fraud, situations involving potential threats to the physical safety of
                  any person, or violations of our Platform terms.
                  <br />
                  <br />
                  <b>iv. Vital Interests</b>
                  <br />
                  We may process your personal and sensitive personal data where it is necessary to protect an interest
                  which is essential for your life, health, and bodily safety. For clarity sake, please note that this
                  basis is limited in scope and will come into play in matters of life and death, for instance where We
                  have not obtained your consent (because you are physically or legally incapable of giving it) or have
                  any other appropriate legal basis for processing your personal data and We have to manage a situation
                  of emergency medical care involving you. We will not rely on this basis where you expressly refuse
                  consent (unless you are not competent to do so).
                  <br />
                  <br />
                  <b>v. Public Interest</b>
                  <br />
                  We may process your information where it is necessary for the performance of a task carried out in the
                  public interest or in order to fulfil a public mandate imposed on us by the regulatory bodies.
                </p>
              </div>
            </section>

            <section id="rights" className="section_thing">
              <div>
                <h5>your rights as a data subject</h5>
                <p>
                  Here is something we have not told you yet: because we determine the purposes for and the manner in
                  which your Personal Data may be processed, we are regarded as a data controller.
                  <br />
                  As a data subject, the law vests you with certain rights—they include the right to:
                  <br />
                  a) access Personal Data we hold about you;
                  <br />
                  b) correct such information where you believe it to be inaccurate;
                  <br />
                  c) restrict the processing of your Personal Data in certain circumstances;
                  <br />
                  d) object to the processing of your Personal Data where we intend to process such data for marketing
                  purposes; where feasible, receive all Personal Data you have provided to us—in a structured, commonly
                  used, and machine-readable format—and to transmit the information to another data controller;
                  <br />
                  e) withdraw your consent;
                  <br />
                  f) request the erasure of your Personal Data (also known as the right to be forgotten); and <br />
                  g) lodge a complaint with a relevant authority, where you have reason to believe that we have violated
                  the term(s) of this Privacy policy. (You may lodge a complaint or seek redress from us within 30 days
                  from the time you first detected the alleged violation.).
                  <br />
                  You may seek to exercise any of the above rights at any time by sending us an email at:
                  hello@awabahng.com . In addition, we would treat your complaint within thirty (30) days, if we are
                  unable to resolve it and require more time, we would inform you promptly. <br />
                  For the purpose of this policy, the supervisory authority is the National Information Technology
                  Development Agency (NITDA) and the complaint can be send via email at dpo@nitda.gov.ng.
                  <br />
                  Cookies and Web Beacons
                  <br />
                  A cookie is a small text file, which includes a unique identifier, which is sent by a web server to
                  the browser on your computer, mobile phone or any other internet-enabled device when you visit an
                  on-line site. Cookies and similar technologies are widely used to make websites work efficiently and
                  to collect information about your online preferences. For simplicity, we refer to all these
                  technologies as "cookies". The cookies we use can be categorised into strictly necessary,
                  functionality, and analytics cookies.
                  <br />
                  We also use web beacons—electronic images (or single-pixel gifs) that we use to help deliver cookies
                  on our Online Channels. We also use web beacons in our emails and newsletters. Therefore, when we send
                  emails to you, we may track behavior such as who opened the emails. This allows us to measure the
                  performance of our email and newsletters campaigns. Please read our cookie notice here.
                </p>
              </div>
            </section>

            <section id="user" className="section_thing">
              <div>
                <h5>USER PROFILES</h5>
                <p>
                  Every registered user on our Website and Mobile Application has a unique personal profile which only
                  you the ability to can access your profile.
                  <br />
                  When you register as a user, we may require certain requisite information at the time of account
                  creation, such as full name, gender, email address, and telephone number. We use these details to
                  create your profile. Your username and password (your login details) are your passport to seamlessly
                  use Our Platform without having to fill out registration forms with information you've already
                  provided. Even if you switch computers, you won't have to re-register – just use your login details to
                  identify yourself. You hereby agree that any information you give to Us will always be accurate,
                  correct and up to date. web beacons—electronic images (or single-pixel gifs) that we use to help
                  deliver cookies on our Online Channels. We also use web beacons in our emails and newsletters.
                  Therefore, when we send emails to you, we may track behavior such as who opened the emails. This
                  allows us to measure the performance of our email and newsletters campaigns. Please read our cookie
                  notice here.
                </p>
              </div>
            </section>

            <section id="third" className="section_thing">
              <div>
                <h5>INFORMATION SHARING WITH THIRD PARTIES</h5>
                <p>
                  There’s nothing to worry about.
                  <br />
                  We may, in certain circumstances, share your information with third parties, such as services
                  providers or partners to enable the functionality of Our Platform(s) or improve or deliver our
                  services. When we do have to share your information with third parties, we take steps to protect your
                  information by requiring these third parties to enter into a Data Processing Contract with us that
                  requires them to use the information we transfer to them in a manner that is consistent with this
                  Privacy Policy and applicable laws.
                  <br />
                  We may also disclose your information to third parties: <br />
                  I. We may share your personal information with Service Providers to monitor and analyse the use of our
                  Service;
                  <br />
                  II. We may share or transfer your personal information in connection with, or during due diligence or
                  negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of
                  our business to another company;
                  <br />
                  III. If you give your explicit consent;
                  <br />
                  IV. If we have to complete a contract on your behalf;
                  <br />
                  V. If there is a legal obligation on us to share such data under existing laws and regulations. We may
                  disclose Your Personal Data in the good faith belief that such action is necessary to:
                  <br />
                  ▪ Comply with a legal obligation;
                  <br />
                  ▪ Protect and defend our rights or property;
                  <br />
                  ▪ to protect the public against dishonesty, malpractice or other seriously improper behavior;
                  <br />
                  ▪ Prevent or investigate possible wrongdoing in connection with the Services;
                  <br />
                  ▪ Protect the personal safety of users of our Service or the public; and
                  <br />
                  ▪ Protect against legal liability.
                  <br />
                  Finally, we may disclose your information to safeguard the rights, property, or safety of Awabah
                  Nigeria, our user base, or others. This could include, but is not limited to, exchanging information
                  with other businesses and organizations for several purposes, ranging from ensuring your pension
                  contribution and investments with our approved investment partners.
                </p>
              </div>
            </section>

            <section id="security" className="section_thing">
              <div>
                <h5>SECURITY IS TAKEN SERIOUSLY</h5>
                <p>
                  At Awabah Nigeria, we implement a variety of reasonable security measures to protect the
                  confidentiality, security, and integrity of your personal and sensitive personal data. We have
                  implemented controls and security safeguards at the technical, physical, administrative and
                  operational levels. Our Platform uses Secure Sockets Layer to ensure secure transmission of your data
                  and communications between your browser and Our servers. Notwithstanding all our efforts to secure
                  your information, we remind you that the transmission of data via the Internet is not completely
                  secure. Therefore, we cannot guarantee that security breaches will never occur. Also please note that
                  if you use Our Platform or Services through a potentially non-secure internet connection, such use is
                  at your own risk.
                </p>
              </div>
            </section>

            <section id="retention" className="section_thing">
              <div>
                <h5>retention of personal information</h5>
                <p>
                  We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations
                  (for example, if we are required to retain your data to comply with applicable laws), resolve
                  disputes, and enforce our legal agreements and policies. We will also retain Usage Data for internal
                  analysis purposes for a period of time as is required for security and functionality purposes.
                </p>
              </div>
            </section>

            <section id="international" className="section_thing">
              <div>
                <h5>INTERNATIONAL TRANSFER</h5>
                <p>
                  Your information, including Personal Data, is processed at the Company's operating offices and in any
                  other places where the parties involved in the processing are located. It means that this information
                  may be transferred to — and maintained on — computers located outside Nigeria or other governmental
                  jurisdiction where the data protection laws may differ than Nigeria. Where we need to transfer your
                  Personal Data to another country, we will take all reasonable steps to ensure that any such transfers
                  comply with applicable law. Where you are accessing our website or Services from outside Nigeria,
                  please note that any information collected may be processed in accordance with Nigerian laws, which
                  may be different from the laws applicable in your country of residence.
                </p>
              </div>
            </section>

            <section id="links" className="section_thing">
              <div>
                <h5>links to other websites</h5>
                <p>
                  Our Service may contain links to other websites that are not operated by us. If you click on a third
                  party link, you will be directed to that third party's site. We strongly advise You to review the
                  Privacy Policy of every site You visit. We have no control over and assume no responsibility for the
                  content, privacy policies or practices of any third party sites or services.
                </p>
              </div>
            </section>

            <section id="changes" className="section_thing">
              <div>
                <h5>changes to our privacy policy</h5>
                <p>
                  Please don’t make this the last time you read it as we will post any changes on this page. Significant
                  changes will of course be notified to you by email.
                </p>
              </div>
            </section>
          </div>
        </div>
      </VerticalTab>
      <ProductsContainer>
        <Questions />
      </ProductsContainer>
      <NewFooter />
    </PrivacyStyle>
  );
};

export default Privacy;
