import React from 'react';
import Nigga from '../../libs/assets/flag-space.svg';
import Group1 from '../../libs/assets/e-group1.svg';
import Group2 from '../../libs/assets/e-group2.svg';
import Group3 from '../../libs/assets/e-group3.svg';
import Group4 from '../../libs/assets/e-group4.svg';
import Group5 from '../../libs/assets/e-group5.svg';

const Eligibility = () => {
  return (
    <div className="eligibility">
      <h4>Who is eligible for the Micro Pension Scheme?</h4>
      <div className="eligibility_group mx-auto">
        <div>
          <img src={Nigga} alt="" />
          <h6>Nigerians</h6>
          <p>You must be a Nigerian citizen to register for the Micro Pension Scheme</p>
        </div>

        <div>
          <img src={Group1} alt="" />
          <h6>Over 18s</h6>
          <p>Participants must be adults to register. 18 years or older</p>
        </div>

        <div>
          <img src={Group2} alt="" />
          <h6>Self Employed</h6>
          <p>People who operate their own businesses.</p>
        </div>

        <div>
          <img src={Group3} alt="" />
          <h6>Employed with 3 People or Less</h6>
          <p>People working at organisations with 3 employees or less.</p>
        </div>

        <div>
          <img src={Group4} alt="" />
          <h6>No Formal Contributory Plan</h6>
          <p>Participants must not be registered in the formal Contributory Pension Scheme</p>
        </div>

        <div>
          <img src={Group5} alt="" />
          <h6>Verifiable Source of Income</h6>
          <p>You have a legal and verifiable source of income recognised by the government</p>
        </div>
      </div>
    </div>
  );
};

export default Eligibility;
