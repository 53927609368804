import styled from 'styled-components';

export const VerticalTab = styled.div`
  background-color: #e5e5e521;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  .group_contain {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    display: grid;
    grid-template-columns: 25% auto;
  }
  .containing_menu {
    background-color: white;
    padding-top: 25px;
    padding-right: 15px;
    .sub_menu a {
      text-decoration: none;
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      color: #3d663d;
    }
  }

  .section_thing {
    padding-left: 4%;
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      text-transform: uppercase;
      color: #3d663d;
    }
    p {
      font-size: 15px;
      line-height: 26px;
      color: #262626;
    }
  }
`;
