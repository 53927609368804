import React from 'react';
import Standing from '../../libs/assets/standing.png';
import Girl from '../../libs/assets/avatar_girl.png';
import Desk from '../../libs/assets/happy_desk.png';
import Family from '../../libs/assets/family.png';
import Walking from '../../libs/assets/happy_walking.png';
import Pension from '../../libs/assets/pension.png';
const WhoCanRegister = () => {
  return (
    <div className="row who-can-register mt-5">
      <div className="col-lg-10 m-auto text-center">
        <h5 className="my-4">
          Who can register for the Micro Pension Scheme<span className="text-success">?</span>
        </h5>
        <div className="boxes-section d-flex flex-wrap">
          <div className="box">
            <img src={Standing} alt="standing" />
            <h6>Nigerians</h6>
            <small>You must be a Nigerian citizen to register for any micro pension plan</small>
          </div>

          <div className="box">
            <img src={Girl} alt="girl" />
            <h6>Over 18s</h6>
            <small>You must be an adult, 18 years or older to qualify</small>
          </div>

          <div className="box">
            <img src={Walking} alt="family" />
            <h6>Employed with 3 people or less</h6>
            <small>If you work at an organisation with 3 employees or less</small>
          </div>

          <div className="box">
            <img src={Desk} alt="desk" />
            <h6>Self Employed</h6>
            <small>If you operate your own business</small>
          </div>

          <div className="box">
            <img src={Pension} alt="family" />
            <h6>No formal contributory plan</h6>
            <small>You have not registered for the formal contributory plan</small>
          </div>

          <div className="box">
            <img src={Family} alt="family" />
            <h6>Have a legitimate source of income</h6>
            <small>You have a legal and verifiable source of income recognised by the government</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoCanRegister;
