import React from 'react';
import { Link } from 'react-router-dom';
import IconThree from '../../libs/assets/img3.png';
import DashboardNav from '../../layouts/DashboardNav';
import CustomButton from '../shared/core/CustomButton';
import { SIGN_UP_URL } from '../../libs/api/base/BaseUrl';

const Pencom = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <DashboardNav />
        <div className="col-lg-9 m-auto">
          <Link to="/dashboard" style={{ color: 'black' }}>
            <p className="mt-3">
              <i className="fa fa-angle-left" /> Back
            </p>
          </Link>
          <div className="d-flex col-lg-12 mt-4 p-0">
            <div className="mr-auto pencom-img-container">
              <img src={IconThree} alt="icon" />
            </div>
            <div className="col-lg-9 pencom-container pl-0">
              <h5>Micro Pension Scheme - PENCOM Number</h5>
              <p className="my-3">
                Take the first step to securing your financial future. Register for the Micro Pension Scheme. Every
                contributor gets a Personal Identification Number issued upon opening a retirement savings account and
                are automatically registered in the PENCOM database. Your PENCOM number is transferrable and moves with
                you when you secure formal employment.
              </p>
              <a href={`${SIGN_UP_URL}`} className="mr-3" target="_blank" rel="noopener noreferrer">
                <CustomButton classname="btn btn-success" name="Register now" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pencom;
