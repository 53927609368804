/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import GeneralNav from '../../layouts/GeneralNav';
import { PrivacyStyle } from '../../styled/StyledPrivacy';
import { ProductsContainer } from '../../styled/StyledProducts';
import NewFooter from '../../layouts/NewFooter';
import Questions from '../product/Questions';
import Carousel from '../carousel/Carousel';
import { OurProducts } from './styled/OurProducts';

const About = () => {
  return (
    <PrivacyStyle className="container-fluid">
      <GeneralNav />
      <div className="mt-4 privacy_header">
        <h4>About Awabah</h4>
        <small>Enabling access to financial security for the unbanked and underbanked</small>
      </div>
      <Carousel />

      <div className="sub_mission col-lg-8 my-4 mx-auto">
        <p>
          Our mission is to help people outside of the regular 9-5 work model plan towards a financially safe and secure
          future. We believe access to financial services should be available to every Nigerian. This is why we work to
          make access to micro pension sercives to any Nigerian.
        </p>
      </div>

      <OurProducts>
        <h4>Our Products</h4>
        <p>There are different ways Awabah can work for you.</p>
        <div id="demo" className="carousel slide col-lg-10 mx-auto" data-ride="carousel">
          <ul className="carousel-indicators">
            <li data-target="#demo" data-slide-to="0" className="active" />
            <li data-target="#demo" data-slide-to="1" />
            <li data-target="#demo" data-slide-to="2" />
          </ul>
          <div className="carousel-inner mx-auto">
            <div className="carousel-item active">
              <div className="carousel-caption mt-4 text-left">
                <h5>
                  <b>Micro Pensions Scheme</b>
                </h5>
                <p className="text-justify">
                  Our mission is to help people outside of the regular 9-5 work model plan towards a financially secure
                  future. We believe financial services should be available to anyone regardless of their financial or
                  work status. Which is why we have worked to set up
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption mt-4 text-left">
                <h5>
                  <b>Micro Pensions Scheme</b>
                </h5>
                <p className="text-justify">
                  Our mission is to help people outside of the regular 9-5 work model plan towards a financially secure
                  future. We believe financial services should be available to anyone regardless of their financial or
                  work status. Which is why we have worked to set up
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption mt-4 text-left">
                <h5>
                  <b>Micro Pensions Scheme</b>
                </h5>
                <p className="text-justify">
                  Our mission is to help people outside of the regular 9-5 work model plan towards a financially secure
                  future. We believe financial services should be available to anyone regardless of their financial or
                  work status. Which is why we have worked to set up
                </p>
              </div>
            </div>
          </div>
        </div>
      </OurProducts>

      <div>
        <h4 className="text-center in_the_news">Awabah in the news</h4>
        <div className="news">
          <div className="news_box">
            <div className="news_image">
              <img
                src="https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJ1c2luZXNzJTIwbWVldGluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                alt=""
              />
            </div>
            <div className="news_details">
              <h5 className="green_text">TECH CABAL</h5>
              <h5 className="sub_text">In Conversation With Awabah</h5>
              <p>
                A lot of people with 9-5’s probably see pensions as a given. Some amount gets deducted from your salary
                and your employer contributes the rest. You watch your millions pile up and wonder when you’ll get to
                retire and enjoy all of that m...
              </p>
            </div>
          </div>

          <div className="news_box">
            <div className="news_image">
              <img
                src="https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJ1c2luZXNzJTIwbWVldGluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                alt=""
              />
            </div>
            <div className="news_details">
              <h5 className="green_text">AFRICA BUSINESS RADIO</h5>
              <h5 className="sub_text">Exploring The Goldmine That Is Micro Pensions with Tunji Andrews</h5>
              <p>
                Nigeria's Micro Pension industry has been described as a gold mine waiting to be tapped. A report from
                Price Waterhouse Cooper showed that by the end of 2017, it was estimated th...
              </p>
            </div>
          </div>

          <div className="news_box">
            <div className="news_image">
              <img
                src="https://images.unsplash.com/photo-1590650516494-0c8e4a4dd67e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGJ1c2luZXNzJTIwbWVldGluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                alt=""
              />
            </div>
            <div className="news_details">
              <h5 className="green_text">VANGUARD NIGERIA</h5>
              <h5 className="sub_text">Tunji Andrews and the race to make all Nigerians pensionable</h5>
              <p>
                Tunji Andrews an advocate of financial inclusion shares the story of his company, Awabah, a
                Mirco-Pension startup tech. Andrews once argued that not everybody living in Nigeria ear...
              </p>
            </div>
          </div>
        </div>
      </div>

      <ProductsContainer>
        <Questions />
      </ProductsContainer>

      <NewFooter />
    </PrivacyStyle>
  );
};

export default About;
