import React from 'react';
import GeneralNav from '../../layouts/GeneralNav';
import { PrivacyStyle } from '../../styled/StyledPrivacy';
import NewFooter from '../../layouts/NewFooter';
import Carousel from '../carousel/Carousel';

const Faq = () => {
  return (
    <PrivacyStyle className="container-fluid">
      <GeneralNav />
      <div className="mt-4 privacy_header">
        <h4>Awabah FAQs</h4>
        <small>Enabling access to financial security for the unbanked and underbanked</small>
      </div>
      <Carousel />

      <NewFooter />
    </PrivacyStyle>
  );
};

export default Faq;
