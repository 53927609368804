import React from 'react';
import GeneralNav from '../../layouts/GeneralNav';
import NewFooter from '../../layouts/NewFooter';
import { ProductsContainer } from '../../styled/StyledProducts';
import ProductsHeader from './ProductsHeader';
import Contributions from './Contributions';
import Questions from './Questions';
const Products = () => {
  return (
    <ProductsContainer className="container-fluid">
      <GeneralNav />
      <ProductsHeader />
      <Contributions />
      <Questions />
      <NewFooter />
    </ProductsContainer>
  );
};

export default Products;
