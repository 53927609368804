import React from 'react';
import { Link } from 'react-router-dom';
import CustomLink from '../shared/core/CustomLink';
import ROUTES from '../../routes/url';

const RegisteringWithAwabah = () => {
  return (
    <div className="registration_for_micro text-white">
      <div>
        <h4 className="text-white big-h4">
          {' '}
          Registering <br />
          for the Micro Pension Scheme with Awabah.
        </h4>
        <button className="btn btn-outline-light btn-default">Get Started</button>
      </div>

      <div className="register_box">
        <h2>1</h2>
        <p>
          Create an <br /> Awabah Account
        </p>
        <small>
          An email, phone number and password will be required to complete this process. An account verification code
          will be sent to your phone number so keep your phone close during registration.
        </small>
      </div>

      <div className="register_box">
        <h2>2</h2>
        <p>Register for the Micro Pension Scheme</p>
        <small>
          Using one of our partners, you can register for the Micro Pension Scheme. A PENCOM number will be assigned to
          you by the National Pension Commision after registration.
          <CustomLink name="See the list of registration requirements." to={ROUTES.REQUIREMENTS} />
        </small>
      </div>

      <div className="register_box">
        <h2>3</h2>
        <p>
          Start Saving for the <br />
          Future
        </p>
        <small>
          Start putting money away! Make payments into your Micro Pension account whenever you please. The savings
          amount and frequency is completely up to you.
        </small>
      </div>
    </div>
  );
};

export default RegisteringWithAwabah;
