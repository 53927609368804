import React from 'react';

const RequirementHeader = () => {
  return (
    <div className="more-products mt-2">
      <div className="col-lg-9 m-auto py-5">
        <h4 className="text-white">Micro Pension Registration Requirements</h4>
      </div>
    </div>
  );
};

export default RequirementHeader;
