import React from 'react';
import { ProductsContainer } from '../../styled/StyledProducts';
import GeneralNav from '../../layouts/GeneralNav';
import RequirementHeader from './RequirementHeader';
import NewFooter from '../../layouts/NewFooter';
import RequiredItems from './RequiredItems';

const Requirements = () => {
  return (
    <ProductsContainer className="container-fluid">
      <GeneralNav />
      <RequirementHeader />
      <RequiredItems />
      <NewFooter />
    </ProductsContainer>
  );
};

export default Requirements;
