import React from 'react';
import Flower from '../../libs/assets/flowerBox.svg';
import DOT3 from '../../libs/assets/Dots-3.svg';

const StartPlanning = () => {
  return (
    <div>
      <div className="retirement-scheme">
        <h3>
          Start planning your retirement.
          <br /> Join the Micro Pension Scheme with Awabah.
        </h3>
        <div className="col-lg-9 d-flex flower-box m-auto p-0">
          <div className="col-lg-6 list-part">
            <h5>We make planning for retirement easier for many Nigerians. We help you:</h5>
            <div>
              <div className="d-flex">
                <i className="fa fa-check-circle" />
                <p className="mt-1">
                  Seamlessly register for the Micro Pension Scheme under the National Pension Commission
                </p>
              </div>

              <div className="d-flex">
                <i className="fa fa-check-circle" />
                <p className="mt-1">Make contributions directly to your retirement account from Awabah</p>
              </div>

              <div className="d-flex">
                <i className="fa fa-check-circle" />
                <p className="mt-1">
                  Monitor your retirement account. Participants can opt for frequent updates via email or text message
                </p>
              </div>

              <div className="d-flex">
                <i className="fa fa-check-circle" />
                <p className="mt-1">
                  Withdraw from your pension account directly from Awabah. The process is simple and stress-free*
                </p>
              </div>
            </div>
          </div>
          <div className="flower-sec">
            <img src={Flower} alt=".." />
          </div>
        </div>
      </div>

      <div className="dot3">
        <img src={DOT3} alt="" />
      </div>
    </div>
  );
};

export default StartPlanning;
