const ROUTES = {
  ABOUT: '/about',
  EXTERNAL_LOGIN: 'https://test.awabahng.com/sign-in',
  EXTERNAL_REGISTER: 'https://test.awabahng.com/register',
  FAQ: '/faq',
  PRODUCTS: '/products',
  PRIVACY: '/privacy',
  REQUIREMENTS: '/requirements',
  PENCOM: '/pencom',
  PENSION: '/pension',
  // PENSION: '/pension',
  // PENSION: '/pension',
};
export default ROUTES;
