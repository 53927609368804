import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay } from 'swiper/core';
import CameraGirl from '../../libs/assets/camera_lady.jpg';
import Slider2 from '../../libs/assets/slider2.jpg';
import Slider3 from '../../libs/assets/slider3.jpg';
import Slider4 from '../../libs/assets/slider4.jpg';
import CustomImage from '../shared/core/CustomImage';
// install Swiper modules
SwiperCore.use([Autoplay]);

const MutipleSlidesPerView = () => {
  return (
    <Swiper
      spaceBetween={-60}
      slidesPerView={4}
      loop
      autoplay={true}
      className="mySwiper mt-5"
      style={{ marginLeft: '-15px', marginRight: '-15px' }}
    >
      <SwiperSlide>
        <CustomImage src={CameraGirl} alt="camera girl" width="80%" />
      </SwiperSlide>
      <SwiperSlide>
        <CustomImage src={Slider2} alt="slider 2" width="80%" />
      </SwiperSlide>
      <SwiperSlide>
        <CustomImage src={Slider3} alt="slider 3" width="80%" />
      </SwiperSlide>
      <SwiperSlide>
        <CustomImage src={Slider4} alt="slider 4" width="80%" />
      </SwiperSlide>
    </Swiper>
  );
};
export default MutipleSlidesPerView;
