import styled from 'styled-components';
import Eligibility from '../libs/assets/Eligibility.svg';
import Security from '../libs/assets/Security.svg';

export const WrapperStyles = styled.div`
  .retirement-scheme {
    margin-top: 6rem;
    position: relative;
    z-index: 1;
  }
  .retirement-scheme h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 78px;
    text-align: center;
    color: #262626;
  }
  .flower-box {
    border: 3px solid #3d663d;
    box-sizing: border-box;
    border-radius: 5px;
    h5 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #262626;
    }
    i {
      margin-top: 7px;
      margin-right: 25px;
      color: #ebcaca;
    }
    .list-part {
      padding: 2.5rem;
    }
  }
  .flower-sec img {
    width: 100%;
  }
  .micro-pension {
    background-color: #f6fef6;
    left: 0;
    margin-top: 5%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
  }
  .pension-scheme {
    font-style: normal;
    font-weight: bold;
    font-size: 41px;
    line-height: 64px;
    color: #262626;
  }
  .contain_i_p p {
    font-size: 15px;
    line-height: 26px;
    color: #262626;
    margin-left: 3px;
    margin-right: 3px;
  }
  .contain_i_p i {
    color: #3d663d;
  }
  .eligibility {
    background-image: url(${Eligibility});
    max-width: 100%;
    margin-top: 5%;
    background-repeat: no-repeat;
    background-position: left top;
    h4 {
      font-size: 41px;
      line-height: 78px;
      color: #262626;
      font-weight: bold;
      padding-top: 55px;
      text-align: center;
    }
    .eligibility_group {
      display: grid;
      grid-template-columns: 1fr repeat(2, 1fr);
      grid-row-gap: 20%;
      width: 90%;
      margin-top: 10%;
      grid-column-gap: 10%;
    }
    .eligibility_group h6 {
      font-weight: bold;
      margin-top: 15px;
    }
  }

  .registration_for_micro {
    display: grid;
    grid-template-columns: 1fr repeat(3, 1fr);
    grid-gap: 27px;
    background-color: #3d663d;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 10%;
    padding-top: 8%;
    padding-bottom: 8%;
    padding-left: 7%;
    padding-right: 7%;
    .big-h4 {
      font-size: 32px;
      line-height: 58px;
      font-weight: bold;
    }
    .register_box {
      background-color: rgba(255, 255, 255, 0.05);
      padding: 20px;
      border-radius: 8px;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }
    h2,
    p {
      font-weight: bold;
      color: white !important;
      line-height: 28px;
    }
    small {
      color: white;
      line-height: 28px;
      font-size: 87%;
    }
  }

  .shield {
    display: grid;
    grid-template-columns: 50% 1fr;
    background-image: url(${Security});
    max-width: 100%;
    margin-top: 5%;
    background-repeat: no-repeat;
    background-position: right top;
    .listed {
      font-size: 16px;
      line-height: 26px;
      color: #333333;
      margin: 0px 16px;
    }
    img {
      width: 70%;
    }
    h4 {
      font-size: 41px;
      line-height: 58px;
      color: #262626;
      font-weight: bold;
      padding-top: 50px;
    }
  }

  .dot3{
    position: absolute;
    left: 74%;
    top: 1200px;
    img{
      width: 70%;
    }
  }
`;

export const PlanStyles = styled.div`
  padding-left: 40px !important;
  padding-right: 40px !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 155px;
  .poka-dot{
    position: absolute;
    left: 0;
    bottom: 0;
    img{
      width: 60%;
    }
  }
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 73px;
    color: #333333;
    margin-top: 25%;
  }
  small {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #333333;
  }
`;

export const CalculatorStyles = styled.div`
  margin-top: 13%;
  color: white !important;
  .dot1{
    position: absolute;
    right: 0;
    top: 75px;
    img{
      height: 300px;
    }
  }
  .retirement-calculator {
    position: relative;
    background: #3d663d;
    text-align: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    div {
      border-bottom: 1px solid #fafafa47;
      padding: 3%;
    }
    .calculate-saving {
      color: white !important;
      display: flex;
      justify-content: space-between;
      small {
        font-size: 16px;
        margin-top: 8px;
      }
      p {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.9);
        box-sizing: border-box;
        border-radius: 6px;
        padding: 12px 24px;
        font-weight: 600;
      }
      select {
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.9);
        box-sizing: border-box;
        border-radius: 6px !important;
        color: rgba(255, 255, 255, 0.9) !important;
        margin-bottom: 1rem;
        font-weight: 600;
      }
    }
    .total-saving {
      width: 410px;
      height: 80px;
      left: 841px;
      top: 573px;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 8px;
      margin: auto;
      small {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: rgba(255, 255, 255, 0.4);
      }
      p {
        font-size: 28px;
        font-weight: 600;
      }
    }
  }
`;
