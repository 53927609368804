import styled from 'styled-components';

export const QuestionsStyles = styled.p`
  font-size: 18px;
  line-height: 30px;
  color: #262626;
  margin: 4px 0px;
  font-weight: 600;
`;


