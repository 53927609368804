import React from 'react';
import { Registration } from '../../styled/StyledRegistration';

const RequiredItems = () => {
  return (
    <Registration className="col-lg-9 mx-auto">
      <p>The following items are required for registration to the Micro Pension Scheme</p>
      <div>
        <div className="d-flex">
          <i className="fa fa-check-circle mt-1 mr-3" />
          <div>
            <p className="mb-0">One Passport Photograph</p>
            <p>
              One (1) recent coloured passport photograph taken against a white background. Face and ears must be
              completely visible.
            </p>
          </div>
        </div>

        <div className="d-flex">
          <i className="fa fa-check-circle mt-1 mr-3" />
          <div>
            <p className="mb-0">National Identity Number (NIN)</p>
            <p>A valid National Identity Number. You may also be asked to upload a scanned version of your NIN card.</p>
          </div>
        </div>

        <div className="d-flex">
          <i className="fa fa-check-circle mt-1 mr-3" />
          <div>
            <p className="mb-0">Valid Means of Identfication.</p>
            <p>
              This can be any of the following:
              <li>National Identity Card or Enrolment Slip</li>
              <li>Bio data page of current international passport</li>
              <li>Official/Company Identification card</li>
              <li>Driver’s License</li>
              <li>Permanent Voter’s card</li>
            </p>
          </div>
        </div>
      </div>
    </Registration>
  );
};

export default RequiredItems;
