import React from 'react';

const PlanCta = () => {
  return (
    <div>
      <h4>
        Plan for a{' '}
        <span className="underline">
          retirement <br />{' '}
        </span>
        you will enjoy!
      </h4>
      <small>
        Awabah helps millions of Nigerians get on the Micro Pension Scheme, the first step to a financially secure
        future.
      </small>
    </div>
  );
};

export default PlanCta;
