import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Pension from '../components/pension/Pension';
import Pencom from '../components/home/Pencom';
import Home from '../components/home/Home';
import Products from '../components/product/Products';
import Requirements from '../components/requirement/Requirements';
import Privacy from '../components/privacy/Privacy';
import About from '../components/about/About';
import Faq from '../components/faq/Faq';
import ROUTES from './url';
const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path={ROUTES.PENSION} component={Pension} />
      <Route exact path={ROUTES.PENCOM} component={Pencom} />
      <Route exact path={ROUTES.PRODUCTS} component={Products} />
      <Route exact path={ROUTES.REQUIREMENTS} component={Requirements} />
      <Route exact path={ROUTES.PRIVACY} component={Privacy} />
      <Route exact path={ROUTES.ABOUT} component={About} />
      <Route exact path={ROUTES.FAQ} component={Faq} />
    </Switch>
  );
};

export default App;
