import styled from 'styled-components';

export const NavStyles = styled.nav`
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 0px !important;
  .nav-link {
    color: #333333;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin: 0px 2px;
  }
  .btn-success {
    background: #3d663d !important;
    border: 1px solid #3d663d;
  }
`;

export const ButtonStyles = styled.button`
  font-weight: 600 !important;
  font-size: 16px !important;
  height: 40px !important;
  padding: 8px 27px !important;
  border-radius: 6px;
`;
