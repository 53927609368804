import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../libs/assets/logo2_3.png';
import SmNavIcon from '../libs/assets/navbar.png';
import CustomImage from '../components/shared/core/CustomImage';
import { LOGIN_URL, SIGN_UP_URL } from '../libs/api/base/BaseUrl';

const Nav = () => {
  return (
    <div className="row">
      <nav className="navbar navbar-expand-lg navbar-light col-12">
        <div className="col-sm-3 col-md-2 logo-part">
          <Link to={'/'}>
            <CustomImage src={Logo} alt="awabah-log" classname="logo logo-w" width="" />
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img src={SmNavIcon} alt="icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto mt-3 plans">
            <li className="nav-item">
              <Link className="nav-link" to="/pension">
                FAQs
              </Link>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto mt-3">
            <a href={`${LOGIN_URL}`} className="mr-3" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-outline-success">Sign in</button>
            </a>

            <a href={`${SIGN_UP_URL}`} className="mr-3" target="_blank" rel="noopener noreferrer">
              <button className="btn btn-success">Create an Account</button>
            </a>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
