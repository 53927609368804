import React from 'react';

const ProductsHeader = () => {
  return (
    <div className="more-products mt-2">
      <div className="col-lg-9 m-auto py-5">
        <h3>More Awabah products</h3>
        <small>In addition to Micro Pensions, we offer other savings products to our customers.</small>
      </div>
    </div>
  );
};

export default ProductsHeader;
