import styled from 'styled-components';

export const FooterStyles = styled.div`
  margin-top: 5%;
  .cta-section {
    background-color: #3d663d;
    position: relative;
    // width: 100%;
    // left: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    margin-right: -31px;
    margin-left: -15px;
    .setup {
      text-align: center;
      h2 {
        font-size: 42px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        padding-top: 16%;
        font-weight: bold;
        margin-bottom: 12px;
      }
      small {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
  button {
    background: white;
    border: 1px solid white;
    color: #3d663d;
    margin-top: 20px;
    &:hover {
      background: #3d663d;
      color: white;
      border: 1px solid white;
    }
  }

  .linked-footer {
    // top: 1960px;
    // position: absolute;
    display: grid;
    grid-template-columns: 1fr repeat(3, 1fr);
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 30px;
    p {
      font-size: 15px;
    }
    .footer-header {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #3d663d;
    }
    .twenty-twenty {
      margin-top: 10%;
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        opacity: 0.8;
      }
    }
  }
`;
