import React from 'react';
import Nav from '../../layouts/Nav';
import Footer from '../../layouts/Footer';
import WhoCanRegister from './WhoCanRegister';
import WhatIsMicroPension from './WhatIsMicroPension';
import Benefits from './Benefits';

const Pension = () => {
  return (
    <div className="container-fluid">
      <Nav />
      <div className="row awabah-pension">
        <div className="text-center w-100">
          <h3>Awabah Micro Pension Scheme</h3>
        </div>
      </div>
      <WhatIsMicroPension />
      <WhoCanRegister />
      <Benefits />
      <Footer />
    </div>
  );
};

export default Pension;
