import React from 'react';
import { CREATE_PENCOM_ACCOUNT_URL } from '../../libs/api/base/BaseUrl';
import CustomButton from '../shared/core/CustomButton';

const WhatIsMicroPension = () => {
  return (
    <div className="row mt-2">
      <div className="col-lg-10 m-auto what-is-pension">
        <h5 className="my-4">What is Micro-Pensions?</h5>
        <p>
          The Pension Reform Act (PRA) 2014 expanded coverage of the Contributory Pension Scheme to the self-employed
          and persons working in organizations with less than 3 employees. As this category of workers constitute the
          larger percentage of the working population in Nigeria, all efforts are on deck to extend coverage to this
          segment of the economy. Due to their widely dispersed nature and generally low and irregular incomes, there is
          need to provide a pension plan that would meet their special characteristics. In this regard, the Micro
          Pension Plan initiative has been conceived.
        </p>
        <a href={`${CREATE_PENCOM_ACCOUNT_URL}`} className="mr-3" target="_blank" rel="noopener noreferrer">
          <CustomButton name="Create a Micro Pensions Account" classname="btn btn-success take-assess mt-1" />
        </a>
      </div>
    </div>
  );
};

export default WhatIsMicroPension;
