import React from 'react';
import AwabahLogo from '../libs/assets/awabah_logo.svg';
import CTA1 from '../libs/assets/CTA1.svg';
import CTA2 from '../libs/assets/CTA2.svg';
import { FooterStyles } from '../styled/StyledFooter';
import CustomLink from '../components/shared/core/CustomLink';
import ROUTES from '../routes/url';

const NewFooter = () => {
  return (
    <FooterStyles>
      <div className="cta-section">
        <div>
          <img src={CTA1} alt="cta1" />
        </div>

        <div className="setup">
          <h2>Set up your future now!</h2>
          <small>Join Awabah to get on the Micro Pension Scheme.</small>
          <br />
          <button className="btn btn-outline-dark btn-default">Join Awabah</button>
        </div>

        <div>
          <img src={CTA2} alt="cta2" />
        </div>
      </div>

      <div className="linked-footer">
        <div>
          <p className="footer-header">Products</p>
          <p>Micro Pension Scheme</p>
          <p>Contributions</p>
          <p>Contributions Plus</p>
          <p>Retirement Calculator</p>
          <p>Requirements for Micro Pension Registration </p>
        </div>

        <div>
          <p className="footer-header">Other links</p>
          <p>
            <CustomLink name="About Us" to={ROUTES.ABOUT} />
          </p>
          <p>
            <CustomLink name="Privacy Policy" to={ROUTES.PRIVACY} />
          </p>
          <p>Terms and Conditions</p>
          <p>
            <CustomLink name="Frequently Asked Questions" to={ROUTES.FAQ} />
          </p>
        </div>

        <div>
          <p className="footer-header">Contact us</p>
          <p>hello@awabahng.com</p>
        </div>

        <div>
          <img src={AwabahLogo} width="60%" alt="logo" />
        </div>
        <div className="twenty-twenty">
          <div>
            <p>&#169; 2020 Awabah</p>
          </div>
          <div>
            <i style={{ fontSize: '24px' }} className="fa">
              &#xf099;
            </i>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
};

export default NewFooter;
