import React from 'react';
import MicroImg from '../../libs/assets/microImg.svg';

const MicroPensionScheme = () => {
  return (
    <div className="micro-pension">
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ paddingLeft: '5rem' }}>
          <h3 className="pension-scheme">What is a Micro Pension Scheme?</h3>
          <div className="mt-4">
            <div className="d-flex contain_i_p">
              <i className="fa fa-check-circle mt-1 mr-3" />
              <div>
                <p>
                  An expanded coverage of the Contributory Pension Scheme included in the Pension Reform Act (PRA) of
                  2014
                </p>
              </div>
            </div>

            <div className="d-flex contain_i_p">
              <i className="fa fa-check-circle mt-1 mr-3" />
              <div>
                <p>
                  Micro Pensions help a large percentage of Nigerians who are not on the Contributory Pension Scheme
                  (pension from structured 9-5 employment) gain access to pension services.{' '}
                </p>
              </div>
            </div>

            <div className="d-flex contain_i_p">
              <i className="fa fa-check-circle mt-1 mr-3" />
              <div>
                <p>
                  The Micro Pension Scheme is for the self-employed and people working in organizations with less than 3
                  employees (MSMEs)
                </p>
              </div>
            </div>

            <div className="d-flex contain_i_p">
              <i className="fa fa-check-circle mt-1 mr-3" />
              <div>
                <p>
                  Just like the Contributory Pension Scheme, the Micro Pension Scheme is regulated by the National
                  Pension Commission
                </p>
              </div>
            </div>

            <div className="d-flex contain_i_p">
              <i className="fa fa-check-circle mt-1 mr-3" />
              <div>
                <p>
                  Participants of the Micro Pensions Scheme are allocated a PENCOM number, a unique identification
                  number
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-4">
          <img src={MicroImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MicroPensionScheme;
