import React from 'react';
import GeneralNav from '../../layouts/GeneralNav';
import NewFooter from '../../layouts/NewFooter';
import { PlanStyles, WrapperStyles } from '../../styled/StyledLanding';
import { ProductsContainer } from '../../styled/StyledProducts';
import PlanCta from './PlanCta';
import RetirementCalculator from './RetirementCalculator';
import StartPlanning from './StartPlanning';
import shield from '../../libs/assets/Shield-group.svg';
import DOT2 from '../../libs/assets/Dots-2.svg';
import Questions from '../product/Questions';
import MicroPensionScheme from './MicroPensionScheme';
import Eligibility from './Eligibility';
import RegisteringWithAwabah from './RegisteringWithAwabah';

const Home = () => {
  return (
    <WrapperStyles className="container-fluid">
      <GeneralNav />
      <PlanStyles>
        <PlanCta />
        <RetirementCalculator />
        <div className="poka-dot">
          <img src={DOT2} alt="" />
        </div>
      </PlanStyles>
      <StartPlanning />
      <MicroPensionScheme />
      <Eligibility />
      <RegisteringWithAwabah />
      <div className="shield">
        <div>
          <img src={shield} alt="" />
        </div>

        <div>
          <h4>Your future is secure with Awabah.</h4>
          <p>
            We employ many measures to ensure that your pension and savings are always protected. They include:
            <div className="d-flex py-2">
              <i className="fa fa-check-circle mt-2" />
              <p className="mt-1 listed">State of the art security and encryption measures</p>
            </div>
            <div className="d-flex py-2">
              <i className="fa fa-check-circle mt-2" />
              <p className="mt-1 listed">Secure payment gateway</p>
            </div>
            <div className="d-flex py-2">
              <i className="fa fa-check-circle mt-2" />
              <p className="mt-1 listed">World-class data encryption</p>
            </div>
          </p>
        </div>
      </div>
      <hr />
      <ProductsContainer>
        <Questions />
      </ProductsContainer>
      <NewFooter />
    </WrapperStyles>
  );
};

export default Home;
